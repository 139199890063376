import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`MindBody Front End Engineer Homework`}</h1>
    <p>{`When applying at MB, they asked they're applicants to do some "homework". When I received the repo containing what I was supposed to build, I was excited. I saw plenty of potential.`}</p>
    <p>{`They gave me a desktop design and said use whatever tools you need to do the job. At the time I had built a few apps using the framework called `}<a href="https://github.com/jorgebucaran/hyperapp" target="_blank">{`hyperapp`}</a>{`, which I decided to use for this homework.`}</p>
    <a href="https://czaas-fee-hw.netlify.com" target="_blank">Here's a demo</a>
    <h2>{`Navigation`}</h2>
    <p>{`I immediately hit the drawing board and thought out what the mobile view might look like.`}</p>
    <div className="pure-g">
  <div className="pure-u-1">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ffee-hw-mb-desktop-nav.jpg?alt=media&token=eb6b65da-6b0e-45ff-830e-789e1b92bee8" width="900" height="110" alt="desktop nav" />
  </div>
    </div>
    <p>{`I came up with an idea of how to share the same navigation structure, because I only wanted to define the nav once. The tabs would open up in a dropdown and the subnav would stay where it was at and just slide to the left/right as you needed to scroll in it.`}</p>
    <div className="pure-g">
  <div className="pure-u-1 pure-u-md-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ffee-hw-mobile-nav.jpg?alt=media&token=4806521f-f86d-41f1-9b45-f97aef5ddda4" width="356" height="200" alt="mobile nav" />
  </div>
  <div className="pure-u-1 pure-u-md-1-2">
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ffee-hw-mobile-nav-opened.jpg?alt=media&token=b69c3c19-f691-4940-a53c-1300037e3935" width="448" height="385" alt="mobile nav opened" />
  </div>
    </div>
    <p>{`Click around the tabs and sub nav to see the state of the active item change. That's stored in state and persists across mobile due to the structure I implemented here.`}</p>
    <h2>{`Data Structure`}</h2>
    <p>{`I made sure to save the content in state so when I added new programs, they would opperate in the same fashion. You could add any number of new programs and delete any number of programs (all stored in locally in state).`}</p>
    <p>{`The state held on to whether the card details were opened or closed and shared that with the "All Programs Overview" at the bottom of the page.`}</p>
    <p>{`Hover over the pencil to see the trashcan and click delete to see the card disapear as well as the program overview below.`}</p>
    <h2>{`New Program Modal`}</h2>
    <p>{`After clicking on the "Add Program" button you are presented with a modal. There are three sections that can be interacted with and in order to create the new program two sections are required. It has an interactive validation on it.`}</p>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ffee-hw-new-program-modal.jpg?alt=media&token=b0421d70-77fc-4646-ad17-1f1d3e32eb13" width="458" height="594" />
    <p>{`The first section is choosing a program type. This essentially is a radio button selection as it can only be of one of the types. But in order to proceed the the next section you `}<em parentName="p">{`must`}</em>{` choose one.`}</p>
    <p>{`The second section is the Program name. On blur of the input field you it validates and either enables the third section or disables it if there is no text. Try adding some text, change some values, go back and remove the text and see that the validation as you hover won't allow you to create the program. (You can still tab through and change the options but can't create the program).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      